import * as React from "react";
import { Anchor, Button, Heading, Paragraph } from "grommet";

const HuntingPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>
        Capacity
      </Heading>
      <Paragraph fill>
        Os{" "}
        <strong style={{color:"#3600b2"}}>
          processos, modelos de negócios, canais de atendimento e,
          consequentemente, a forma de trabalho
        </strong>{" "}
        vem sofrendo mudanças constantes devido a transformação digital e{" "}
        <strong style={{color:"#3600b2"}}>exigindo agilidade dos envolvidos em se reinventar</strong> para
        atender as necessidades do mercado.
      </Paragraph>
      <Paragraph fill style={{ marginBottom: "25px" }}>
        Os{" "}
        <strong style={{color:"#3600b2"}}>
          avanços da tecnologia estão trazendo grandes inovações e exigindo dos
          profissionais novas habilidades
        </strong>{" "}
        para acompanhar essas tendências, perseguindo nossa missão de disseminar
        a cultura “data-driven”{" "}
        <strong style={{color:"#3600b2"}}>
          ofertamos aos nossos parceiros e clientes um programa de capacitação,
          mentoria e transformação da cultura organizacional.
        </strong>
      </Paragraph>

      <Paragraph fill style={{ color: "#000000" }}>
        Nossa{" "}
        <strong style={{color:"#3600b2"}}>
          metodologia contempla além do conteúdo teórico o compartilhamento de
          experiencias e conhecimento, as melhores práticas de mercado, estudos
          de casos de sucesso e experiências práticas em desafios reais do dia a
          dia
        </strong>
        , permitindo que o profissional se sinta pronto para incorporar de
        imediato as novas capacidades requeridas.
        <ul>
          <li>Capacitação e Orientação para Gestores</li>

          <li style={{ marginTop: "25px" }}>
            Promovendo a Cultura Data-Driven na Cultura Organizacional
          </li>

          <li style={{ marginTop: "25px" }}>Gestão e Governança de Dados</li>

          <li style={{ marginTop: "25px" }}>Capacitação em Ciência de Dados</li>
        </ul>
      </Paragraph>
      <Paragraph fill style={{ textAlign: "center" }}>
        <a href="/contact">
          <Button
            type="submit"
            label="FALE COM NOSSO TIME"
            primary
            size="small"
            style={{ width: "250px", margin: "10px" }}
          />
        </a>{" "}
        <a href="/contact">
          <Button
            type="submit"
            label="CONTRATAR"
            primary
            size="small"
            style={{ width: "250px" }}
          />
        </a>
      </Paragraph>
    </>
  );
};

export default HuntingPage;
